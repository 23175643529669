<template>
    <v-card class="d-flex flex-column fill-height" flat>
        <!-- {{valid}} {{currentModel.PuntiVenditaInclusi.length}} {{currentModel.Clusters.length}} {{currentModel.PuntiVenditaEsclusi.length}} -->
        <v-card-text>
            <v-stepper v-model="step" v-if="ready">
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1" editable :rules="[() => step1Valid]"
                        >Seleziona Clienti</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" step="2" :editable="step1Valid" :rules="[() => valid || step !== 2]"
                        >Contenuti</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 3" step="3" :editable="valid && step1Valid" :rules="[() => step3Valid || step !== 3]"
                        >Seleziona Negozi</v-stepper-step
                    >

                    <v-divider></v-divider>

                    <v-stepper-step step="4" :editable="valid && step1Valid && step3Valid"
                        >Escludi Negozi</v-stepper-step
                    >

                    <v-divider></v-divider>
                </v-stepper-header>
                <v-stepper-items class="steppers-container">
                    <v-stepper-content step="1">
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <v-list>
                                <!-- <v-list-item v-for="(item, i) in items" :key="i" link :to="item.to"> -->
                                <v-list-item-group v-model="selectedBrand" multiple>
                                <v-list-item
                                    v-for="item in currentModel.FarmsId"
                                    :key="item.IDConsorzio" :value="item"
                                >
                                <template v-slot:default="{active}">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" :true-value="item"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-title>{{
                                        item.Consorzio
                                    }}</v-list-item-title>
                                </template>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Promo
                                :model.sync="currentModel"
                                :farms.sync="selectedBrand"
                                :valid.sync="valid"
                            ></Promo>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step="3" v-if="step >= 2">
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Stores
                                :stores.sync="currentModel.PuntiVenditaInclusi"
                                :farms.sync="selectedBrand"
                            ></Stores>
                        </div>
                    </v-stepper-content>
                    <v-stepper-content step="4" v-if="step >= 2">
                        <div class="d-flex flex-grow-1 flex-shrink-0 ma-2">
                            <Stores
                                :stores.sync="currentModel.PuntiVenditaEsclusi"
                                :farms.sync="selectedBrand"
                            ></Stores>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
            <!-- {{ currentModel.PuntiVenditaInclusi.length }} {{ currentModel.PuntiVenditaEsclusi.length }} -->
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="step = step + 1" v-if="step !== 4" :disabled="!canContinue">
                Continua
            </v-btn>
            <v-btn
                color="primary"
                @click="insertPromo"
                :disabled="!completed"
                v-if="step === 4"
            >
                Inserisci
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as storeActions from "../../../store/actions/store";

export const Promo = () => import("../components/Promo");
export const ImportStores = () => import("../components/ImportStores");
export const Stores = () => import("../components/StoresSelector");

export default {
    props: {
        model: {
            type: Object,
            required: true
        }
    },

    components: {
        Promo,
        Stores
        // ImportStores
    },

    data: () => ({
        ready: false,
        step: 1,
        valid: false,
        currentModel: {},
        importedStores: [],
        selectedBrand: []
    }),

    computed: {
        ...mapState({
            loaded: state => state.storeModule.loaded
        }),

        completed() {
            return this.valid && this.step1Valid && this.step3Valid;
        },

        canContinue() {
            return this.step === 1 && this.step1Valid || this.step === 2 && this.valid || this.step === 3 && this.step3Valid
        },

        step1Valid() {
            return this.selectedBrand.length !== 0
        },

        step3Valid() {
            return this.currentModel.PuntiVenditaInclusi !== undefined && this.currentModel.PuntiVenditaInclusi.length !== 0
        }
    },

    watch: {
        step: {
            handler: function(newVal) {
                if (typeof this.step === "string") {
                    console.log("converto in numero");
                    this.step = parseInt(this.step);
                }
                console.log(newVal, typeof this.step, typeof newVal);
                if (newVal === 3) {
                    // merge imported stores
                    // creo array merge con valori unici per evitare duplicati
                    const merged = [
                        ...new Set([
                            ...this.importedStores,
                            ...this.currentModel.PuntiVenditaInclusi
                        ])
                    ];
                    // console.log(this.importedStores, this.currentModel.PuntiVendita, merged.length)
                    this.currentModel.PuntiVenditaInclusi = merged;
                }
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),

        async getStores(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadStores(farmId);
                }
            } catch (err) {
                console.error(err);
            }
        },

        insertPromo() {
            // console.log('vuoi proprio farmi inversare 1?', this.currentModel.PuntiVenditaInclusi)
            this.currentModel.Consorzi = this.selectedBrand.map(x => x.IDConsorzio)
            // console.log('vuoi proprio farmi inversare 2?', this.currentModel.PuntiVenditaInclusi)
            this.$emit("saveData", this.currentModel);
        }
    },

    beforeMount() {
        this.currentModel = JSON.parse(JSON.stringify(this.model));
    },

    mounted() {
        this.$nextTick(async () => {
            this.ready = true;
            // await this.getStores(this.model.FarmId);
        });
    }
};
</script>

<style scoped>
.steppers-container {
    height: 600px;
}
</style>
